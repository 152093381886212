import request from '@/utils/request'

//---------公告管理---------

//查询公告
export function getNotice(data) {
  return request({
    url: '/MA_notice/list',
    method: 'get',
    params: data
  })
}

//新增公告
export function addNotice(query, data) {
  return request({
    url: '/MA_notice/add',
    method: 'post',
    params: query,
    data: data
  })
}


//修改公告
export function editNotice(query, data) {
  return request({
    url: '/MA_notice/update',
    method: 'post',
    params: query,
    data: data
  })
}


//删除公告
export function deleteNotice(data) {
  return request({
    url: '/MA_notice/delete',
    method: 'get',
    params: data
  })
}
