<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline">
        <el-form-item label="公告标题">
          <el-input v-model="formInline.title" clearable placeholder="请输入公告标题"></el-input>
        </el-form-item>
        <el-form-item label="所属社区">
					<el-select v-model="formInline.communityCode" filterable style="width: 100% !important;" placeholder="请选择所属社区">
						<el-option v-for="(item,index) in sheQuList" :key="index" :label="item.name" :value="item.levelcode">
						</el-option>
					</el-select>
				</el-form-item>
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="formInline.time"
            clearable
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="addRow">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod">
      </el-table-column>
      <el-table-column
        label="公告标题"
        prop="title">
      </el-table-column>
      <el-table-column
        label="所属社区"
        prop="communityname">
      </el-table-column>
      <el-table-column
        label="公告内容"
        prop="content">
      </el-table-column>
      <el-table-column
        label="终止时间"
        prop="expire_time">
      </el-table-column>
      <el-table-column
        label="操作"
        width="200">
        <template slot-scope="scope">
          <el-button @click="editRow(scope.row)" type="primary" size="mini">修改</el-button>
          <el-button @click="deleteRow(scope.row)" type="danger" size="mini">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div>
    <el-dialog class="dialogForm" :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="900px">
      <el-form :model="form" :rules="rules" ref="form" label-width="150px" style="width: 94%;">
        <el-form-item label="公告标题" prop="title">
          <el-input v-model="form.title" clearable placeholder="请输入公告标题"></el-input>
        </el-form-item>
        <el-form-item label="所属社区" prop="content">
          <el-select
            v-model="form.communityCode"
            multiple
            collapse-tags
            placeholder="请选择所属社区">
            <el-option
              v-for="item in sheQuList"
              :key="item.levelcode"
              :label="item.name"
              :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公告内容" prop="content">
          <el-input :rows="3" type="textarea" v-model="form.content" placeholder="请输入公告内容"></el-input>
        </el-form-item>
        <el-form-item label="结束时间" prop="expire_time">
          <el-date-picker v-model="form.expire_time" value-format="yyyy-MM-dd" type="date" placeholder="请选择结束时间"></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-show="editType === 'add'" @click="resetForm('form')">重置</el-button>
        <el-button type="primary" @click="submitForm('form')">确定提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getNotice, addNotice, editNotice, deleteNotice } from '@/api/noticeManage'
import { isShequ } from '@/api/shequ/xiaoqu.js'
import { sureMessageBox }　from '@/utils/common'
export default {
  name: "noticemanage",
  data () {
    return {
      sheQuList: [],
      formInline: {
        title: '',
        communityCode: '',
        time: '',
      },
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
      tableData: [],
      title: '',
      dialogFormVisible: false,
      editType: '',
      form: {
        uuid: '',
        title: '',
        content: '',
        expire_time: ''
      },
      rules: {
        title: [
          { required: true, message: '请输入公告标题', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入公告内容', trigger: 'blur' }
        ],
        expire_time: [
          { required: true, message: '请选择结束时间', trigger: 'blur' }
        ]
      },
    };
  },

  components: {},

  computed: {},

  mounted(){
    this.getSheQu();
    this.getList();
  },

  methods: {
    getSheQu() {
      isShequ({userid:this.GLOBAL.adminId()}).then(res=>{
				if(res.result==200){
					this.sheQuList=res.detail;
				}else{
					this.$message.error(res.description)
				}
			})
    },
    getList() {
      getNotice({
        title: this.formInline.title,
        communityCode: this.formInline.communityCode,
        beginTime: this.formInline.time[0] || '',
        endTime: this.formInline.time[1] || '',
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }).then( res => {
        if( res.result == 200) {
          this.tableData = res.detail.list;
          this.totalRow = res.detail.totalRow;
        }
      })
    },
    //自定义序号
    indexMethod(index) {
      return (this.currentPage - 1)*this.pageSize + (index+1);
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    addRow() {
      this.title = "新增公告内容";
      this.editType = 'add';
      this.dialogFormVisible = true;
      this.resetForm('form');
    },
    editRow(row) {
      console.log("row:",row);
      this.title = '修改公告内容';
      this.editType = 'update';
      this.resetForm('form');
      this.dialogFormVisible = true;
      this.form.uuid = row.uuid;
      this.form.title = row.title;
      this.form.communityCode = row.communitycode.split(',');
      this.form.content = row.content;
      this.form.expire_time = row.expire_time;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if( this.editType == 'add') {
            addNotice({
              communityCodes: this.form.communityCode.join(',')
            },{
              TITLE: this.form.title,
              CONTENT: this.form.content,
              EXPIRE_TIME: this.form.expire_time
            }).then( res => {
              if(res.result == 200) {
                this.$message.success('添加成功');
                this.currentPage = 1;
                this.getList();
                this.dialogFormVisible = false;
              } else {
                this.$message.warning(res.description)
              }
            })
          } else {
            editNotice({
              communityCodes: this.form.communityCode.join(',')
            },{
              uuid: this.form.uuid,
              title: this.form.title,
              content: this.form.content,
              expire_time: this.form.expire_time
            }).then( res => {
              if(res.result == 200) {
                this.$message.success('修改成功')
                this.getList();
                this.dialogFormVisible = false;
              } else {
                this.$message.warning(res.description)
              }
            })
          }
        }
      })
    },
    deleteRow(row){
      sureMessageBox('是否确认删除该公告信息？').then(res=>{
        deleteNotice({
          uuid: row.uuid
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功')
            this.getList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    resetForm(formName) {
      this.form = {
        title: '',
        content: '',
        expire_time: ''
      }
      if (this.$refs[formName] !== undefined) {
        this.$refs[formName].resetFields();
      }
    },
  }
}

</script>
<style lang='scss' scoped>
.searchbox {
  margin: 20px 10px;
}
.pagebox{
  margin: 20px 0;
  text-align: right;
}
</style>